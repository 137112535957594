<template>
  <div class="aboutme">
    <div class="about_img">
      <!-- <el-image
        class="aboutimg"
        src="https://wallpaperm.cmcm.com/d0642723426873998239a09d010435ca.jpg"
        fit="cover"
      ></el-image> -->
      <video
        autoplay="autoplay"
        muted
        loop
        :src="bannerData.bannerUrl"
        v-if="bannerData.type == 2"
      ></video>
      <el-image
        class="aboutimg"
        :src="bannerData.bannerUrl"
        v-if="bannerData.type == 1"
      ></el-image>
    </div>
    <div class="changeType">
      <!-- <div class="changebox">
        <div class="changeinbox hvr-bounce-to-right" v-animation:home="'a-fadeinT'">企业简介</div>
        <div class="changeinbox hvr-bounce-to-right" v-animation:home="'a-fadeinT'">文化理念</div>
        <div class="changeinbox hvr-bounce-to-right" v-animation:home="'a-fadeinT'">核心优势</div>
      </div> -->
    </div>
    <div class="type1">
      <div class="top1" v-animation:home="'a-fadeinT'">
        <div class="left">
          <div class="left_top">黑蜻蜓集团</div>
          <div class="left_bottom">
            “黑蜻蜓”是黑蜻蜓集团旗下共享充电行业标杆品牌，致力于为用户提供一站式的手机移动充电解决方案。<br /><br />
            我们目前已为全国超过80%
            的城市实现业务服务落地，覆盖全国一二、三四线城市，终端网点40万+，黑蜻蜓不仅是一款智能、高效、便捷的共享充电宝，更是一款多功能、多用途、多盈利的社交场景平台，这也让我们的产品更有发展投资潜力与市场竞争力！
          </div>
        </div>
        <div class="right">
          <el-image
            class="rightimg"
            :src="require('../assets/gs.jpg')"
            fit="cover"
          ></el-image>
        </div>
      </div>
      <div class="top2" v-animation:home="'a-fadeinT'">
        <div class="anlibox">
          <el-image
            class="anli_top"
            :src="require('../assets/aboutimg/chengshi.png')"
            fit="contain"
          ></el-image>
          <div class="anli_bottom">
            <div class="maintext">400+</div>
            <div>点亮城市</div>
          </div>
        </div>
        <div class="anlibox">
          <el-image
            class="anli_top"
            :src="require('../assets/aboutimg/dianpu.png')"
            fit="contain"
          ></el-image>
          <!-- <div class="anli_top"></div> -->
          <div class="anli_bottom">
            <div class="maintext">40万+</div>
            <div>终端网点</div>
          </div>
        </div>
        <div class="anlibox">
          <el-image
            class="anli_top"
            :src="require('../assets/aboutimg/dingwei.png')"
            fit="contain"
          ></el-image>
          <div class="anli_bottom">
            <div class="maintext">5亿＋</div>
            <div>使用黑蜻蜓共享充电服务</div>
          </div>
        </div>
        <div class="anlibox">
          <el-image
            class="anli_top"
            :src="require('../assets/aboutimg/hehuoren.png')"
            fit="contain"
          ></el-image>
          <div class="anli_bottom">
            <div class="maintext">10000+</div>
            <div>黑蜻蜓合伙人</div>
          </div>
        </div>
      </div>
      <div class="top3" v-animation:home="'a-fadeinT'">
        <div class="top3_top">实现未来，实现财富梦想</div>
        <div class="top3_bottom">
          共享充电宝的有效使用主要依靠用户流量的带动，不同场景下不同人流量会直接影响共享充电宝的使用频次。如商场、餐厅、火车站、机场、酒店、KTV、休闲场所、景区等地方都是共享充电宝的高频使用场景。在这些高频使用场景下摆放共享充电宝，利润收成非常可观。
        </div>
      </div>
      <div class="top4" v-animation:home="'a-fadeinT'">
        <!--        <div class="top4_box" v-for="item in 1" :key="item">-->
        <div class="top_img">
          <el-image
            class="top4_img"
            :src="require('../assets/aboutimg/about_company.jpg')"
            fit="cover"
          ></el-image>
        </div>
        <div class="top4_title"></div>
        <!--        </div>-->
      </div>
    </div>
    <div class="button_gg">
      <HelloWorld></HelloWorld>
    </div>
  </div>
</template>
<script>
import HelloWorld from "../components/HelloWorld";
import { banner } from "@/api/api_list";
export default {
  components: {
    HelloWorld,
  },
  data() {
    return {
      bannerData: [],
    };
  },
  created() {
    banner({ location: 6 }).then((res) => {
      if (res.code == 200) {
        this.bannerData = res.data;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.aboutme {
  width: 100%;
  height: 100%;
  background: black;
  .about_img {
    width: 100%;
    height: 45.45vw;
    .aboutimg {
      width: 100%;
      height: 100%;
    }
  }
  .changeType {
    width: 100%;
    padding-top: 50px;
    background: white;
    padding-bottom: 50px;
    .changebox {
      width: 80%;
      height: 50px;
      margin: 0 auto;
      /* background:red; */
      display: flex;
      justify-content: space-around;
      .changeinbox {
        width: 20%;
        height: 50px;
        color: white;
        line-height: 50px;
        text-align: center;
        border: 1px solid #fff;
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .type1 {
    width: 100%;
    margin: 0 auto;
    background: white;
    .top1 {
      width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        width: 44%;
        display: flex;
        flex-direction: column;
        color: black;
        .left_top {
          font-size: 20px;
          font-weight: bold;
        }
        .left_bottom {
          margin-top: 20px;
          font-size: 12px;
          line-height: 20px;
        }
      }
      .right {
        width: 44%;
        .rightimg {
          width: 100%;
        }
      }
    }
    .top2 {
      width: 80%;
      margin: 0 auto;
      padding-top: 20px;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-items: center;
      .anlibox {
        width: 200px;
        height: 120px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .anli_top {
          width: 100px;
          height: 50px;
          margin-bottom: 10px;
        }
        .anli_bottom {
          width: 100%;
          height: 60px;
          box-sizing: border-box;
          border: 1px solid black;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: black;
          font-size: 12px;
          .maintext {
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
    }
    .top3 {
      width: 80%;
      margin: 0 auto;
      padding-top: 20px;
      display: flex;
      color: black;
      flex-direction: column;
      align-items: center;
      .top3_top {
        margin-top: 20px;
        font-size: 20px;
        font-weight: bold;
      }
      .top3_bottom {
        margin-top: 20px;
        line-height: 20px;
        font-size: 12px;
        text-indent: 2em;
        /* font-weight: bold; */
      }
    }
    .top4 {
      padding-top: 50px;
      padding-left: 100px;
      margin: 0 auto;
      width: 50%;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-items: center;
      .top4_box {
        width: 260px;
        height: 200px;
        background: #101010;
        margin-bottom: 10px;
        .top_img {
          width: 100%;
          height: 150px;
          overflow: hidden;
          .top4_img {
            width: 100%;
            height: 100%;
            transition: all 0.6s;
          }
          .top4_img:hover {
            transform: scale(1.1);
          }
        }
        .top4_title {
          /* display: flex;
          align-items: center; */
          padding: 10px;
          box-sizing: border-box;
          width: 100%;
          height: 50px;
          line-height: 20px;
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }
  .button_gg {
    width: 100%;
    height: 100%;
    background: black;
  }
}
</style>
